@import "common.css";

.vo-unit-body {
	.navbarheader {
		position: fixed;
		top: 0;
		margin: 0;
		background: var(--Primarycolor);
		z-index: 3;
	}
}

.unit-view {

	.title-img-container {
		width: 100%;
		cursor: pointer;
	}

	.v-img-gallery {
		margin-top: 20px;
	}

	.search-section {
		.searchbox {
			margin: 0 0 10px 0;
		}

		.search-popup-trigger {
			cursor: pointer;
		}

		.form-control:disabled,
		.form-control[readonly] {
			background-color: white;
		}

	}

	.title-img {

		position: relative;
		width: 100%;
		height: 700px;

		-webkit-background-size: cover;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;


		.v-left-gallery-btn,
		.v-right-gallery-btn {
			font-size: 160px;
			position: absolute;
			top: 50%;
			margin-top: -57px;
		}

		.v-left-gallery-btn:hover,
		.v-right-gallery-btn:hover {
			color: #585858;
		}

		.v-right-gallery-btn {
			right: 40px;
		}

		.v-left-gallery-btn {
			left: 40px;
		}

	}



	.panorama {
		position: relative;
		width: 100%;
		height: 700px;
	}

	.main-section {
		position: relative;
		max-width: 1200px;
		margin-top: 50px;

		h2 {
			margin-top: 40px;
			font-size: 22px;
			font-family: 'Barlow Condensed', sans-serif;
			color: var(--Themelight);

			.stars {
				display: inline-block;
				margin-left: 22px;
				color: #dfb62d;
			}
		}

		.main-col {

			position: relative;
			overflow: hidden;

			.unit-slider {
				display: flex;
				flex-wrap: wrap;
				width: 100%;
			}

			.daterange {
				text-align: center;

				.asd__wrapper {
					display: inline-block;
				}
			}

			.col-md-12 {
				max-width: 100%;
				width: 100%;
				padding: 0;

				.owl-nav {
					button {
						color: #fff;
						outline: none !important;
					}
				}
			}

			.owl-carousel {
				.item {
					img {
						height: 500px;
						object-fit: cover;
						cursor: pointer;
					}
				}
			}

			.owl-carousel div h2,
			.owl-carousel div .textoverlay {
				position: absolute;
				color: #fff;
				text-shadow: 3px 3px 4px #777;
				display: block;
				float: left;
				top: 90%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			.row {
				display: flex;
				flex-wrap: wrap;
				width: 100%;
			}

			.stars {
				margin-top: 10px;
			}

			.description {}


			.intro {
				i {
					font-size: 26px;
				}

				>div {
					text-align: center;
				}
			}

			.properties {
				margin-left: 15px;
				margin-right: 15px;
				@mixin columnlayout 2;

				.prop.bool {
					font-weight: bold;
				}

				.prop.missing {
					text-decoration: line-through;
					font-weight: normal;
				}

				.stars {
					display: inline-block;
					color: #dfb62d;
				}
			}

			.feedbacks {
				margin-top: 30px;

				blockquote {
					div {
						font-family: 'Indie Flower', cursive;
					}

				}
			}


		}


		.info-col {
			position: relative;
			width: 100%;
			/*
				padding: 3px;
				padding-top: 10px;
				position: -webkit-sticky;
				position: sticky;
				top: 10px;
           */

			.info-col-header {
				height: 50px;
				padding: 12px 0;
				background-color: var(--Primarycolor);
				color: #fff;
				text-align: center;

				h2 {
					margin: 0px;
					font-size: 22px;
					font-family: 'Barlow Condensed', sans-serif;
					color: #fff;

				}
			}

			.info-col-main {
				border: 0px solid var(--Primarycolor);
				border-bottom: none;
				width: 100%;
				padding: 15px 15px 1px 15px;

				.asd__wrapper--full-screen {
					z-index: 9 !important;
				}

				.search-section {
					.datepicker-trigger {
						margin: 0 0 10px 0;
					}

					input {
						height: 40px;
						border-radius: 0;
						box-shadow: none;
						font-size: 18px;
					}
				}
			}

			.subprice {
				width: 100%;
				border: 0px solid var(--Primarycolor);
				border-top: none;
				font-size: 18px;
			}

			.fav-section {
				margin-top: 20px;
				border-bottom: 1px solid var(--Primarycolor) !important;
				padding: 18px;
			}

			.info-col-top {
				border: 2px solid var(--Primarycolor);
				margin: 0 0 30px 0;

				.unit-fav-button {
					.btn-block {
						text-align: center;
						background-color: #fff;
						border: 1px solid var(--Themelight);
						border-radius: 2px;
						line-height: 38px;
						color: var(--Themelight) !important;
						padding: 8px 20px;
						display: flex;
						-webkit-box-align: center;
						align-items: center;
						-webkit-box-pack: center;
						justify-content: center;
						height: 48px;
						font-size: 18px;
						letter-spacing: .05rem;
						width: 100%;
						outline: none;

						&:hover {
							color: var(--Reetlandred) !important;
							border: 1px solid var(--Reetlandred) !important;
							box-shadow: none;
						}

						i {
							margin: 0 5px 0 0;
						}
					}
				}
			}

			.subprice {

				padding: 1px 15px 15px 15px;

				.filters {}


				.book-btn {
					margin-top: 30px;
					margin-bottom: 10px;
					font-size: 18px;
					height: 40px;
					padding: 5px 10px;
					border-radius: 0;
				}

				.btn-go {
					text-align: center;
					background-color: #fff;
					border: 1px solid var(--Themelight);
					border-radius: 2px;
					line-height: 38px;
					color: var(--Themelight) !important;
					padding: 8px 20px;
					display: flex;
					align-items: center;
					justify-content: center;
					height: 48px;
					font-size: 18px;
					letter-spacing: 0.05rem;
					float: right;
					width: 100%;
					outline: none;

					&:hover {
						color: var(--Reetlandred) !important;
						border: 1px solid var(--Reetlandred) !important;
						box-shadow: none;
					}
				}

				.btn-default {
					text-align: center;
					background-color: #fff;
					border: 1px solid var(--Themelight);
					border-radius: 2px;
					line-height: 38px;
					color: var(--Themelight) !important;
					padding: 8px 20px;
					display: flex;
					align-items: center;
					justify-content: center;
					height: 48px;
					font-size: 18px;
					letter-spacing: 0.05rem;
					float: right;
					width: 100%;
					outline: none;

					&.btn-sm {
						padding: 5px 10px;
						font-size: 12px;
						line-height: 1.5;
						border-radius: 3px;
						width: inherit;
						height: 30px;
						float: none;
						display: inline-block;
					}

					&:hover {
						color: var(--Reetlandred) !important;
						border: 1px solid var(--Reetlandred) !important;
						box-shadow: none;
					}
				}

				.flow-buttons {
					button {
						margin-left: 20px;
						display: block;
						width: auto;
						padding: 0 10px;
						height: 48px;
						outline: none;
					}

				}

				.price-table {
					.btn-warning {
						background-color: var(--Primarycolor);
						color: #fff !important;
						border: 1px solid var(--Primarycolor);
					}
				}
			}

			.unit-fav-button {
				padding: 0 15px 15px 15px;
			}
		}

		.price-list {
			max-width: 400px;

			.subline {
				.labelcol {
					padding-left: 20px;
				}

				td {
					border-top: none;
				}
			}
		}
	}


	.subsection {
		width: 100%;

		.unit-map {
			height: 400px;
			width: 100%;
		}
	}


	.matterport-showcase {
		text-align: center;

		iframe {
			min-height: 240px;
			margin: 16px 0;
			width: 100%;
		}

	}


	@media all and (max-width:767px) {

		.title-img {
			height: 350px;

			.v-left-gallery-btn,
			.v-right-gallery-btn {
				font-size: 80px;
			}

		}

		.panorama {
			height: 350px;
		}


		.page-header h1 {
			font-size: 24px;
		}

		.main-section {

			.main-col {
				.properties {
					@mixin columnlayout 1;
				}
			}
		}

		.main-section {
			.main-col {
				.owl-carousel {
					.item {
						img {
							height: 350px;
						}
					}
				}
			}
		}
	}


	@media (min-width:768px) and (max-width:992px) {

		.title-img {
			height: 465px;
		}

		.panorama {
			height: 465px;
		}

		.matterport-showcase iframe {
			min-height: 360px;
		}


	}

	@media (min-width:993px) and (max-width:1199px) {

		.title-img {
			height: 580px;
		}

		.panorama {
			height: 580px;
		}

		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}


	}


	@media screen and (min-width: 993px) {
		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}

	}

	.unit-calendar-section {
		position: relative;

		.asd__inner-wrapper {
			position: static;
		}
	}

}


.facility-view {

	h2 {
		margin-top: 40px;
		font-size: 22px;
	}

	.reduced-width-container {
		max-width: 970px !important;

		.row {
			padding: 5px;
		}

	}
}

.backlink {
	padding: 0 15px;
	margin: 0 0 20px 0;

	a {
		text-align: center;
		background-color: #fff;
		border: 1px solid var(--Themelight);
		border-radius: 2px;
		line-height: 38px;
		color: var(--Themelight);
		padding: 8px 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 48px;
		font-size: 18px;
		letter-spacing: 0.05rem;
		float: right;
		width: 100%;
		margin: 0 0 20px 0;

		&:hover {
			color: var(--Reetlandred) !important;
			border: 1px solid var(--Reetlandred) !important;
			box-shadow: none;
		}
	}

}

.unit-proposals,
.unit-list-section {

	.single-unit {
		float: left;
		margin: 4px;
		background-color: white;
		padding: 3px;

		a {
			color: inherit;
			text-decoration: none;
		}

		h3 {
			margin-top: 10px;
			font-size: 15px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 224px;
		}

		.udetails {

			padding: 2px;
			opacity: 0.7;
			position: relative;

			.prop {
				padding-right: 4px;
			}


			.door {
				width: 10px;
				height: 15px;
				display: inline-block;
				background: no-repeat url(RESOURCE/img/door-smaller.png) 0 0;
				position: relative;
				top: 2px;
			}

		}
	}
}

.unit-proposals {
	background-color: #f8f8f8;
}

.unit-banner {
	position: relative;

	&:after {
		background-color: rgba(0, 0, 0, .5);
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		content: "";
		z-index: 1;
	}

	.title-img {
		position: relative;
		width: 100%;
		height: 400px;
		margin-bottom: 0;
	}

	.page-header {
		position: absolute;
		bottom: 30px;
		width: 100%;
		left: 0;
		z-index: 2;
		margin: 0;
		border-bottom: none;

		h1 {
			margin: 0 0 0 0;
			color: #fff;
			font-size: 40px;

			small {
				color: rgb(189 189 189);
			}
		}

		.breadcrumb {
			padding: 30px 0;
			background: transparent;
			margin: 0;

			.breadcrumb-item {
				padding-left: 8px;
				text-transform: uppercase;

				a {
					font-size: 14px;
					color: #fff;
				}

				span {
					color: var(--Primarycolor);
				}
			}
		}

		.breadcrumb>li+li:before {
			padding: 0 5px;
			color: #ccc;
			content: "/\00a0";
		}
	}
}

.main-section {
	.main-col {
		.unit-name-title {
			margin: 40px 0 20px 0;
			font-size: 40px;
			font-family: 'Barlow Condensed', sans-serif;
			color: var(--Themelight);
		}

		p {
			font-size: 18px;
			line-height: 24px;
			font-weight: 400;
			margin: 0 0 20px;
			//color: var(--Darkgreen);
		}

		h4.text-prim {
			font-size: 25px;
			font-weight: 400;
			color: var(--Themelight);
		}

		.list-unstyled {
			li {
				display: flex;
				width: 100%;
				font-size: 18px;
				line-height: 24px;
				font-weight: 400;
				margin: 0 0 5px;
				color: var(--Darkgreen);

				.fa {
					color: var(--Primarycolor);
					margin: 5px 5px 0 0;
					font-size: 15px;
				}
			}
		}
	}
}

.similar-units {
	padding: 50px 0 0;

	.content-section {
		h2 {
			text-align: left;
			font-size: 40px;
			margin: 0 0 20px;
			width: 100%;
			padding: 0 15px;
			font-family: Barlow Condensed, sans-serif;
			color: var(--Themelight);
			font-weight: 600;
			letter-spacing: .2rem;
			text-transform: uppercase;
		}
	}

	.three-units {

		.col-md-4:last-child {
			display: none;
		}
	}

	.unit-rest-prp {
		.search-result-properties {
			display: flex;
			flex-wrap: wrap;
			margin: 0 0 15px 0;
			width: 100%;

			>div {
				float: left;
				text-align: center;
				background-color: rgb(202 171 115/.7);
				padding: 5px 10px;
				color: #fff;
				border: 1px solid #caab73;
				border: 1px solid var(--Primarycolor);
				margin-right: 5px;
				margin-bottom: 5px;
				display: -webkit-box;
				display: flex;
				-webkit-box-align: center;
				align-items: center;
				-webkit-box-pack: center;
				justify-content: center;
			}

			i {
				margin: 0 5px 0 0;
			}
		}
	}


	.single-unit {
		width: 100%;
		margin: 0;
		padding: 0;

		.unit-img {
			img {
				width: 100%;
				height: 250px;
				object-fit: cover;
			}
		}

		.unit-content {
			padding: 20px 30px 30px;
			background-color: #f5f5f5;
			display: flex;
			flex-wrap: wrap;

			h3 {
				width: 100%;
				margin: 0 0 15px 0;
				font-size: 22px;
				color: var(--Themelight);
				width: 100%;
				line-height: 32px;
				white-space: unset;
			}

			.text-right {
				width: 100%;
			}

			.btn-primary {
				text-align: center;
				background-color: #fff;
				border: 1px solid var(--Themelight);
				border-radius: 2px;
				line-height: 38px;
				color: var(--Themelight);
				padding: 8px 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 48px;
				font-size: 18px;
				letter-spacing: 0.05rem;
				float: right;
				width: 135px;

				&:hover {
					color: var(--Reetlandred) !important;
					border: 1px solid var(--Reetlandred) !important;
					box-shadow: none;
				}
			}
		}
	}

	.unit-list-section {
		display: flex;
		flex-wrap: wrap;
		margin: 0;
		width: 100%;

		.box {
			display: flex;
			margin: 0 0 30px 0;
		}
	}

	.unit-rest-prp {
		min-height: 130px;
	}
}

.owl-carousel-fs {
	.owl-stage-outer {
		min-height: 400px;

		@media all and (max-width:767px) {
			min-height: 200px;
		}
	}
}

.owl-nav {

	button {
		width: 50px;
		height: 60px;
		text-align: center;
		position: absolute;
		top: 50%;
		margin-top: -30px;

		@media (max-width:992px) {
			width: 60px;
		}

		&:before {
			font-size: 40px;
			color: var(--whiteColor);
			font-family: 'Font Awesome 5 Pro';
			font-weight: 900;
		}

		span {
			display: none;
		}

		&.owl-prev {
			left: 0;

			&:before {
				content: "\f104";
			}
		}

		&.owl-next {
			right: 0;

			&:before {
				content: "\f105";
			}
		}
	}
}

.owl-carousel-fs2 {
	.owl-nav {
		button {
			outline: none;
			color: #fff !important;
		}
	}

	.owl-item {
		.item {
			img {
				height: 600px;
				width: 100%;
				object-fit: cover;
			}
		}
	}
}

.owl-thumbs {
	.owl-thumb-item {
		border: none;
		outline: 0 none;
		margin: 0;
		padding: 0;
		float: left;
		margin: 5px 5px 5px 0;

		&.active {
			img {
				opacity: 0.5 !important;
			}
		}
	}

	.userimg {
		width: 68px;
		height: 68px;
		overflow: hidden;
		display: inline-block;
		vertical-align: top;
		position: relative;

		img {
			transform: translate(-50%, -50%);
			position: absolute;
			left: 50%;
			top: 50%;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}




@media (max-width: 767px) {
	.unit-banner {
		.page-header {
			h1 {
				font-size: 30px;
			}

			.breadcrumb {
				padding: 15px 0;
			}
		}
	}

	.owl-carousel-fs2 {

		.owl-item {
			.item {
				img {
					height: 300px;
				}
			}
		}
	}

	.main-section {
		.main-col {
			.unit-name-title {
				font-size: 30px;
			}
		}
	}

	.similar-units {
		.content-section {
			h2 {
				font-size: 30px;
			}
		}
	}
}

.legend-cal {
	text-align: center;
	padding: 0;
	margin: 30px 0 15px 0;

	ul {
		display: flex;
		padding: 0;
		justify-content: center;
		flex-wrap: wrap;

		@media (max-width: 767px) {
			max-width: 370px;
			width: 100%;
			margin: 0 auto 30px;
		}
	}

	li {
		display: inline-flex;
		align-items: center;
		min-height: 30px;
		padding-right: 15px;
		margin: 5px 0;

		@media (max-width: 767px) {
			width: 50%;
			text-align: left;
		}

		&.first {
			@media (max-width: 767px) {
				width: 100%;
			}
		}

		&.last {
			@media (max-width: 767px) {
				width: 100%;
			}
		}

		.day {
			min-width: 30px;
			height: 30px;
			background-color: #7e7975;
			display: inline-block;
			margin: 0 10px;
			border: 1px solid #e4e7e7;

			@media (max-width: 767px) {
				margin: 0 10px 0 0;
			}

			&.day-free {
				background: var(--available);
			}

			&.day-full {
				background: var(--cal_not_available);
			}

			&.day-end {
				background: linear-gradient(135deg, var(--cal_not_available) 50%, var(--available) 50%) !important;
			}

			&.day-start {
				background: linear-gradient(135deg, var(--available) 50%, var(--cal_not_available) 50%) !important;
			}

			&.day-disabled {
				background: var(--disabled) !important;
			}

			&.day-middle {
				background: var(--available) !important;
				color: #fff !important;
				text-align: center;
				font-size: 15px;
				font-style: normal;
				padding-top: 3px;
				margin: 0px !important;
			}

			&.day-selected-range {
				background: var(--selection_range);
			}
		}
	}
}