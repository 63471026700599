@import "setup.css";

.navbar-scroll {
	&.navbarheader {
		position: fixed;
		top: 0;
		z-index: 9;
		visibility: hidden;
		opacity: 0;

		.container {
			max-width: 1220px;
			width: 100%;

			.row {
				margin-left: -7px;
				margin-right: -7px;

				.searchbox {
					padding-left: 7px;
					padding-right: 7px;
					position: relative;

					.warning-message {
						padding: 5px 5px;
						font-size: 14px;
						width: 100%;
						display: block;
						text-align: center;
						margin: 0;
						border-radius: 0px;
					}
				}
			}
		}

		@media (max-width:992px) {
			display: none;
		}

		.row {
			width: 80%;
		}

		&.affix {
			visibility: visible;
			opacity: 1;
		}

		.checkbox-bl {
			margin-top: 11px;

			label {
				font-weight: normal;
			}
		}

		.navbar-scr-bl {
			display: flex;
			align-items: center;
			justify-content: space-between;


			.dropdown {

				&.open {
					.icon-bar {
						&.middle-bar {
							display: none;
						}

						&.bottom-bar {
							position: relative;
							transform: rotate(45deg);
							top: -4px;
						}

						&.top-bar {
							position: relative;
							transform: rotate(-45deg);
							top: -2px;
						}
					}
				}

				.dropdown-menu {
					border-radius: 0;
					background-color: var(--Themelight);
					top: 54px;
					width: 530px;
					border: none;
					padding: 15px;


					ul {
						padding: 0;

						li {
							list-style: none;
							display: inline-block;
							min-width: 120px;
							margin: 9px 20px 9px 20px;

							&.header-search {
								display: none;
							}


							a {
								color: #fff;
								text-transform: uppercase;
								text-decoration: none;
								letter-spacing: 0.09rem;
								font-weight: 400;
								font-size: 1rem;
							}
						}
					}
				}
			}
		}

		.logo {
			max-width: 180px;
		}

		.form-control {
			background-color: #fff;
			border-radius: 0;
		}

		.icon-menu {
			background-color: transparent;
			border: none;
		}

		.icon-bar {
			background-color: var(--menuitem);

			&.icon-bar {
				display: block;
				width: 30px;
				height: 2px;
				border-radius: 1px;
				margin: 0px 0;

				&.middle-bar {
					margin: 5px 0;
				}
			}
		}



		.search-btn {
			border-radius: 0;
			box-shadow: inherit;
			color: #fff;
			width: 100%;
			background: var(--menuitem);
		}

		.white {
			color: #fff;
		}
	}
}

.navbarheader {
	position: absolute;
	width: 100%;
	background: var(--Primarycolor);
	margin-top: 15px;
	padding: 20px 0;
	top: 0;
	z-index: 9;

	@media (max-width:992px) {
		position: fixed;
		padding: 7px 0;
	}

	.logo {
		position: relative;
		height: 0;
		width: 100%;
		padding: 0;
		padding-bottom: 55px;
	}

	.logo svg {
		/*max-height: 55px;*/
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
	}

	.logo svg .st0 {
		fill: var(--white);
	}

	.logo svg .st1 {
		fill: var(--menuitem);
	}



	.navbarheader @media (max-width: 992px) {
		margin-top: 0;
	}

	.container {


		@media (max-width: 992px) {
			width: 100%;
		}

		@media (max-width: 1300px) {
			width: 100%;
			max-width: 1300px;
		}
	}

	&.affix {
		background-color: var(--Primarycolor);
		margin-top: 0;
		box-shadow: 0 0px 4px rgba(0, 0, 0, .1);

		.nav-contact {
			@media (max-width: 992px) {
				top: 9px;
			}
		}
	}


	.logo {
		max-width: 250px;
		display: block;

		img {
			height: 80px;
		}
	}

	.vo-nav-bar {
		width: 90%;

		.hedaer-right-mobile-icon {
			display: none;
		}
	}

	nav {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		height: 60px;

		@media (max-width: 992px) {
			position: static;
		}

		.icon-bar {
			background-color: var(--Secondarycolor);
		}

		.sr-only {
			position: absolute;
			width: 1px;
			height: 1px;
			padding: 0;
			margin: -1px;
			overflow: hidden;
			clip: rect(0, 0, 0, 0);
			border: 0;
		}

		.icon-bar {
			display: block;
			width: 30px;
			height: 2px;
			border-radius: 1px;
			margin: 0px 0;
		}

		.navbar-area {
			@media (min-width:993px) {
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}


			@media (max-width:992px) {
				position: absolute;
				display: none;
				width: 100%;
				background-color: var(--Themelight);
				left: 0;
				margin: 0 !important;
			}

			padding: 0;
			margin: 0;

			li {
				list-style: none;

				a {
					color: var(--menuitem);

					&:hover {
						text-decoration: none;
						color: var(--Secondarycolor);
					}
				}
			}

			li.header-phone {
				margin: 0 10px;
				font-size: 18px;
				font-weight: 400;
				word-spacing: .25em;

				a {
					display: flex;
					align-items: center;

					.fa {
						font-size: 13px;
						width: 27px;
						height: 27px;
						background: var(--Secondarycolor);
						color: var(--Primarycolor);
						border-radius: 100%;
						text-align: center;
						line-height: 26px;
						text-decoration: none;
						display: flex;
						align-items: center;
						justify-content: center;
						margin: 0 10px 0 0;
					}
				}
			}

			li.header-search {
				margin: 0 0 0 10px;

				form {
					padding: 0;
					width: auto;

					.form-control {
						background: transparent;
						border: 1px solid var(--Secondarycolor);
						border-radius: 0;
						height: 35px;
						font-size: 13px;
						color: var(--white);
						width: 165px;
						font-size: 18px;
					}

					.form-control::-webkit-input-placeholder {
						/* Edge */
						color: var(--white);
					}

					.form-control:-ms-input-placeholder {
						/* Internet Explorer */
						color: var(--white);
					}

					.form-control::placeholder {
						color: var(--white);
					}

					.input-group-btn {
						.btn-default {
							font-size: 13px;
							width: 35px;
							height: 35px;
							border-radius: 0;
							text-align: center;
							line-height: 26px;
							text-decoration: none;
							-webkit-box-pack: center;
							justify-content: center;
							margin: 0 10px 0 0;
							display: flex;
							align-items: center;
							border: none;
							background: var(--Secondarycolor);
							color: var(--Primarycolor);
						}
					}
				}
			}
		}
	}


	.nav-contact {
		a {
			color: #a0927e;
			font-size: 15px;
			text-decoration: none !important;
			margin: 0 0 0 5px;
			letter-spacing: 0.5px;

			@media (max-width:992px) {
				margin-left: 5px;
			}
		}

		.fa {
			padding-top: 6px;
			padding-left: 7px;
			font-size: 13px;
			width: 27px;
			height: 27px;
			background: var(--Primarycolor);
			color: #fff;
			border-radius: 100%;
			margin: 0 2px 0 0;
		}


		span {
			padding: 17px 2px;
			color: var(--blackcolor);
			font-size: 15px;

			@media (max-width:1240px) {
				font-size: 13px;
			}

			@media (max-width:1170px) {
				display: none;
			}
		}


		@media (max-width: 768px) {
			display: none;
		}
	}
}


.vo-nav-bar {

	a.nav-item {
		font-size: 18px;
		font-weight: 400;
		word-spacing: 0.25em;
		text-transform: uppercase;
		padding: 20px 10px;
		line-height: 83px;

		@media (max-width:1240px) {
			font-size: 16px;
			padding: 20px 4px;
		}

		@media (max-width:1170px) {
			padding: 20px 10px;
		}

		@media (max-width:992px) {
			line-height: initial;
			padding: 10px 15px;
			font-size: 1rem;
			font-weight: 500;
			color: var(--white) !important;
			letter-spacing: 0.09rem;
		}

		&:hover {
			color: var(--secondarycolor);
			text-decoration: none;
		}
	}


	.icon {
		display: none;
	}

	.vo-dropdown,
	.input-group {
		float: left;
		overflow: hidden;
	}

	.input-group {
		padding: 9px 16px;

		.form-control {}
	}


	.vo-dropdown-content {
		display: none;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 160px;
		box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.1);
		z-index: 1;
	}

	.vo-dropdown-content a {
		float: none;
		color: black;
		padding: 12px 16px;
		text-decoration: none;
		display: block;
		text-align: left;

		&:hover {
			background: #cbcdce;
		}
	}



	.vo-dropdown:hover .vo-dropdown-content {
		display: block;
	}


	@media screen and (max-width: 992px) {

		a:not(:first-child),
		.input-group,
		.vo-dropdown .dropbtn {}

		a.icon {
			float: right;
			display: block;
			position: absolute;
			color: var(--white);
			background-color: #dc143c;
			background-color: transparent;
			border-radius: 50%;
			width: 30px;
			height: 20px;
			text-align: center !important;
			margin-left: 15px;
			line-height: 27px;
			display: flex !important;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			right: 15px;
			margin: 0;
			top: 28px;
		}
	}


	@media (min-width:var(--navbarbreak)) {
		.search {
			width: 150px;
			transition: width .3s ease-in-out;
			-webkit-transition: width .3s ease-in-out;
			-moz-transition: width .3s ease-in-out;
			-ms-transition: width .3s ease-in-out;
		}

		.search:focus-within {
			width: 250px;
		}

		.rightme {
			float: right;
		}

	}



}

@media screen and (max-width: 767px) {
	.navbarheader {
		padding: 7px 0;

		.logo {
			img {
				height: 50px;
			}
		}
	}
}

@media screen and (max-width: 992px) {


	.vo-nav-bar.opened a {
		float: none;
		display: block;
		text-align: left;

	}

	.vo-nav-bar.opened .input-group {
		display: table;
	}

	.vo-nav-bar.opened .vo-dropdown {
		float: none;
	}

	.vo-nav-bar.opened .navbar-area {
		display: block;
		box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
		top: 100%;
		margin: 10px 0 0 0;
	}

	.vo-nav-bar.opened .vo-dropdown .dropbtn {
		display: block;
		width: 100%;
		text-align: left;
	}
}


@media screen and (max-width: 992px) {

	.navbarheader {
		margin-top: 0 !important;

		.vo-nav-bar {
			.hedaer-right-mobile-icon {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				padding: 5px 30px 0 0;

				a {
					font-size: 13px;
					width: 27px;
					height: 27px;
					background: var(--Secondarycolor);
					color: var(--Primarycolor);
					border-radius: 100%;
					text-align: center;
					line-height: 26px;
					text-decoration: none;
					-webkit-box-pack: center;
					justify-content: center;
					margin: 0 10px 0 0;
				}
			}
		}

		.logo {
			width: 100%;
		}

		nav {
			.navbar-area {

				li.header-phone {
					display: none;
				}

				li.header-search {
					height: 45px;

					.form-control {
						width: 100% !important;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 992px) {
	.vo-nav-bar.opened .navbar-area {
		margin: 20px 0 0 !important;
	}
}

@media screen and (min-width: 993px) and (max-width: 1199px) {
	.vo-nav-bar {
		a.nav-item {
			padding: 20px 3px;
			font-size: 11px;
		}
	}


	.navbarheader {
		nav {
			.navbar-area {
				li {
					a {
						font-size: 16px;
					}
				}

				li.header-search {
					form {
						.form-control {
							width: 165px;
						}
					}
				}
			}
		}
	}
}